import React from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import get from 'lodash/get'
import Seo from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/footer'
import Blocks from '../components/blocks'
import Layout from '../components/layout'
import '../sass/main.scss'
// import {
//   setOmCode,
//   setPhoneLocalStorage,
//   setCookieTestUser,
//   getCookieTestUser,
//   gtmTrackingDetailDataLayer,
//   initAntiFlicker,
//   // importEmployerGroups,
//   // importHttpRedirects,
//   // unPulishEntries,
//   // pulishEntries,
//   // deleteEntries,
//   // updateRobots
// } from '../components/utilities'

class RootIndex extends React.Component {
  componentDidMount() {
    const celebrusTagsScript = document.createElement('script')
    celebrusTagsScript.src = process.env.ENSIGHTEN_BOOTSTRAP_TAGS
    celebrusTagsScript.async = true
    document.head.appendChild(celebrusTagsScript)

    const trustarcScript = document.createElement('script');
    trustarcScript.src = '//consent.trustarc.com/notice?domain=cm-nationwide.com&c=teconsent&js=nj&noticeType=bb&text=true&pcookie&gtm=1'
    trustarcScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(trustarcScript);

    // const eGroupsData = get(this.props, 'data.allEmployerGroupsCsv.edges');
    // importEmployerGroups(eGroupsData);

    // const httpRedirects = get(this.props, 'data.allExactRedirectsCsv.edges');
    // importHttpRedirects(httpRedirects);

    // unPulishEntries('employerGroups');
    // deleteEntries('employerGroups');
    // pulishEntries('employerGroups');

    // pulishEntries('httpRedirects');
    // updateRobots();

    const brower = typeof window !== `undefined`
    if (brower) {
      var getOmValue = localStorage.getItem('Petinsurance_OM')
      this.props.setTrackingCode(getOmValue)
      var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone')
      this.props.setTrackingPhone(getPhoneValue)
    }
  }

  render() {
    const page = get(this.props, 'data.contentfulPage')

    return (
      <Layout>
        <Seo
          pageName={page.omniturePageNameLegacy || page.contentName}
          specificPhone={page.specificPhone}
          trackingCode={page.trackingCode}
          title={page.seoTitleTag}
          path=''
          canonical={page.seoCanonicalUrl ? page.seoCanonicalUrl.seoCanonicalUrl : ''}
          description={page.seoMetaDescription ? page.seoMetaDescription.seoMetaDescription : ''}
          robots={page.robotsDirective ? page.robotsDirective.robotsDirective : ''}
          image={page.socialImage}
          showAntiFlickerSnippet={page.showAntiFlickerSnippet}
        />
        <Header header={page.header} specificPhone={page.specificPhone}></Header>
        <main id="main-content" role="main">
          <Blocks blocks={page.rows}></Blocks>
        </main>
        <Footer footer={page.footer} specificPhone={page.specificPhone}></Footer>
      </Layout>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
    setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
  }
}

const mapStateToProps = (state) => {
  return {
    state,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RootIndex)

export const pageQuery = graphql`
     query RootBySlug{
          site {
               siteMetadata {
                    title
               }
          }
          contentfulPage(slug: {eq: "page-greater-than-homepage"}) {
               ...ContentfulPageFields
          }
     }
`

// Used to import Employer Groups or HTTP Redirects
// export const pageQuery = graphql`
//      query RootBySlug{
//           site {
//                siteMetadata {
//                     title
//                }
//           }
//           contentfulPage(slug: {eq: "page-greater-than-homepage"}) {
//                ...ContentfulPageFields
//           }
//           allEmployerGroupsCsv(limit: 100, skip: 8800) {
//                edges {
//                     node {
//                          affiliateNumber
//                          alternateSearchTerm
//                          contentName
//                          groupDisplayName
//                          groupNumber
//                          groupType
//                          importantGroupNotice
//                          omniturePageNameLegacy
//                          pageType
//                          payrollNumber
//                          searchable
//                          seoTitleTag
//                          slug
//                          specificPhone
//                          templateType
//                          vanityUrl
//                          videoUrl
//                          welcomeHeader
//                     }
//                }
//           }
//           allExactRedirectsCsv(limit: 0, skip: 0) {
//                edges {
//                     node {
//                          Content_Name
//                          Domain_Name
//                          Header_Response
//                          Match_Type
//                          New_URL
//                          Old_URL
//                          Old_URL_Character_Count
//                          Redirect_Type
//                     }
//                }
//           }
//      }
// `
